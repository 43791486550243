import React from 'react'
import styled from 'styled-components'
import NavBar from '../components/navbar/Navbar'
import Content from '../components/content/Content'
import Services from '../components/services/Services'
import Contact from '../components/contact/Contact'
import Info from '../components/info/Info'
import Footer from '../components/footer/Footer'
import Background from '../assets/background.png'
import { Request } from 'fetchboi'
import { baseAPIRoot } from '../config/index.config'
import WhatsAppButton from '../components/WhatsAppButton'

Request.init(baseAPIRoot)

export default () => (
  
    
  <Container>
    <ImgSection inputUrl={Background}>
      <NavBar/>
      <Content/>
    </ImgSection>  

    <Services/>

    <ContactSection inputUrl={Background} id="contactSection">
      <Contact/>
    </ContactSection>

    <Info/>
    <Footer/>
    <WhatsAppButton/>
  </Container>

)

const Container = styled.div`
  padding: 0;
    
`
const ImgSection = styled.div`
  background-image: url(${props => props.inputUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const ContactSection = styled.div`
  background-image: url(${props => props.inputUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`
