import * as React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

export default class InfoContact extends React.Component {

    render() {
      return (
        <InfoContactContainer>

          <InfoContactTitle>
            Contactos
          </InfoContactTitle>

          <Row>
            <Col xs={7}>
              <InfoContactDescription>
                Teléfono:
              </InfoContactDescription>
            </Col>

            <Col xs={17}>
              <InfoContactDescription>
                +56 9 4269 4231
              </InfoContactDescription> 
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <InfoContactDescription>
                E-mail:
              </InfoContactDescription>
            </Col>

            <Col xs={17}>
            <InfoContactDescription>
              <EmailLink href="mailto:contacto@trazoevents.com">
                contacto@trazoevents.com
              </EmailLink> 
            </InfoContactDescription>
            </Col>
          </Row>

          <Row>
            <Col xs={7}>
              <InfoContactDescription>
                Dirección:
              </InfoContactDescription>
            </Col>

            <Col xs={17}>
              <InfoContactDescription>
                Av. Edmundo Eluchans
              </InfoContactDescription> 
              <InfoContactDescription>
                3047 Of. 68
              </InfoContactDescription> 
              <InfoContactDescription>
                VM, Cl
              </InfoContactDescription> 
            </Col>

          </Row>
        </InfoContactContainer>
      )
    }
}

const InfoContactContainer = styled.div`
    padding: 50px 0 0 50px;

    @media(max-width: 767px){
      padding: 20px 0 0 50px;
    }

    @media(max-width: 568px){
      padding: 20px 0 0 20px;
    }
    
`
const InfoContactTitle = styled.h4`
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #141414;
`

const InfoContactDescription = styled.p`
    font-size: 16px;
    line-height: 8px;
    letter-spacing: -0.1px;
    color: #616161;
`
const EmailLink = styled.a`
    text-decoration: none;
    font-size: 16px;
    line-height: 8px;
    letter-spacing: -0.1px;
    color: #FF8364;
    margin: -10px 0 0 0;
`