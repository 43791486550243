import * as React from 'react'
import styled from 'styled-components'
import { Row, Col, notification } from 'antd';
import ContactText from './ContactText'
import ContactForm from './ContactForm'
import { sendContact } from '../../request/contact'

export default class Contact extends React.Component {
  state = {
    values: { email: '', phone: '', fullName: '' },
    isLoading: false
  }

  handleChange = (key, value) => {
    this.setState(state => ({
      values: { ...state.values, [key]: value }
    }))
  }

  validarEmail(valor) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(valor)){
      return true
    } else {
      return false
    }
  }

  handleSubmit = async() => {
    const { values } = this.state
    this.setState({ isLoading: true})
    const data = {
      "Email": values.email,
      "Data": {
        "Phone": values.phone,
        "FullName": values.fullName
      }
    }
    console.log('val', this.validarEmail(values.email))
    if(values.email === '' || values.phone === '' || values.fullName === '' ){
      this.setState({ isLoading: false})
      notification['error']({
        message: 'Campos vacíos',
        description:
          'Por favor no dejar campos vacíos',
      });
    }else if (!this.validarEmail(values.email)){
      this.setState({ isLoading: false})
      notification['error']({
        message: 'Email Inválido',
        description:
          'Por favor un email válido',
      });
    }else{
      
      sendContact(null, data)
      .then((resp)=>{
        this.setState({ isLoading: false})
        notification['success']({
          message: 'Mensaje Enviado',
          description:
            'Pronto uno de nuestros ejecutivos se contactará con usted',
        });
      })
      .catch((error)=>{
        this.setState({ isLoading: false})
        notification['error']({
          message: 'Ocurrio un Problema',
          description:
            'Ocurrió un problema inesperado, Por favor intente denuevo',
        });
      })
    }
  }

  render() {
    const { values, isLoading } = this.state
    return (
      <ContactContainer>
        <Row>
          <Col xs={24} md={24} lg={14}>
            <ContactText/>
          </Col>

          <Col xs={24} md={24} lg={10}>
            <ContactForm values={values} onChangeText={this.handleChange} onSubmit={this.handleSubmit} loading={isLoading}/>
          </Col>
        </Row>
      </ContactContainer>
    )
  }
}

const ContactContainer = styled.div`
  padding: 100px 80px;
  max-width: 1300px;
  margin: 0 auto;
  
  @media (max-width:767px){ 
    padding: 40px 10px 0 10px;
  }
`