import * as React from 'react'
import styled from 'styled-components'
import { Button } from 'antd';

export default class ContactButon extends React.Component {

  render() {
      return (
        <a href="#contactSection">
          <BtnContact>Contacto</BtnContact>
        </a>
      )
  }
}

const BtnContact = styled(Button)`
  color: #fff !important;
  background: linear-gradient(357.34deg, #F9A97C -41.98%, #C84C88 175.68%) !important;
  border-radius: 6px !important;
  padding: 0px 25px !important;
  height: 25px !important;
  border: 0 !important;

  :hover{
    background: linear-gradient(357.34deg, #C84C88 -41.98%, #F9A97C 175.68%) !important;  
  }

  @media (max-width: 568px ) {
    display: none !important;
  }
`