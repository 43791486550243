import React from 'react'
import { Spin, Icon } from 'antd';

import styled from 'styled-components'

export default function FullPageLoading({ loading }){
    const antIcon = <Icon type="loading" style={{ fontSize: 24, color:'#fff' }} spin />
    return (
      <LoadingContainer>
        <Spin spinning={loading} indicator={antIcon} />
      </LoadingContainer>
    )
  
}

const LoadingContainer = styled.div`

text-align: center;

border-radius: 4px;
width: 100%;
height: 100%;

`