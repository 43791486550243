import * as React from 'react'
import styled from 'styled-components'
import Title from './Title'
import Card from './Card'

export default class Services extends React.Component {

  render() {
    return (
      <ServicesContainer>
        <Title/>
        <Card/>
      </ServicesContainer>
    )
  }
}

const ServicesContainer = styled.div`
    padding: 100px 80px;
    max-width: 1300px;
    margin: 0 auto;

    @media (max-width:991px){ 
      padding: 40px 10px 0 10px;
      height: 900px;
    }

    @media (max-width:767px){ 
      padding: 40px 10px 0 10px;
      height: 1100px;
    }
    
    @media (max-width:568px){ 
      padding: 40px 10px 0 10px;
      height: 1600px;
    }
`