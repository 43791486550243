import * as React from 'react'
import styled from 'styled-components'
import Trazolabs from '../../assets/logo.png'
import { Row, Col } from 'antd'

export default class Footer extends React.Component {

  render() {
    return (
      <FooterContainer>
        <Row>
          <Col xs={12} >
            <FooterText> Una División de</FooterText>
          </Col>
          <Col xs={12} >
            <LinkTrazo href="https://trazolabs.com" target="_blank">
              <FooterLogo src={Trazolabs} />
            </LinkTrazo>
          </Col>
        </Row>

        <FooterRights>
          Derechos Reservados | Trazo Labs SpA
        </FooterRights>
        
        
      </FooterContainer>
    )
  }
}

const FooterContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    background: #1C3B93;
    padding: 20px;
    
    @media screen and (max-width: 450px) {
      padding-bottom: 100px;
    }
`
const FooterText = styled.p`
    color: #fff;
    float: right;
    margin-right: 7px;
`

const FooterRights = styled.p`
    color: #fff;
    text-align: center;
`

const LinkTrazo = styled.a`
    text-decoration: none;
`
const FooterLogo = styled.img`
    max-width: 100px;
    width: 100%;
`