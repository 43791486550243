import * as React from 'react'
import styled from 'styled-components'
import { Row, Col, Icon } from 'antd';
import { motion } from "framer-motion"

export default class Card extends React.Component {

  render() {
    return (
      <CardServiceContainer>
        <Row>
          <Col xs={24} sm={12} md={8} xl={6} >
            <motion.div 
              whileHover={{ scale: 1.1 }} >
              <CardService>
                  <Icon type="star" />
                  <CardTitle>
                    Aplicación Móvil
                  </CardTitle>
                  <CardSubTitle>
                    Como canal directo y constante de comunicación
                  </CardSubTitle>
              </CardService>  
            </motion.div>
          </Col>

          <Col xs={24} sm={12} md={8} xl={6} >
            <motion.div whileHover={{ scale: 1.1 }} >
              <CardService>
                  <Icon type="star" />
                  <CardTitle>
                    Panel de Administrador
                  </CardTitle>
                  <CardSubTitle>
                    Que potencia y mejora la gestión de la organización
                  </CardSubTitle>
              </CardService>  
            </motion.div>
          </Col>

          <Col xs={24} sm={12} md={8} xl={6} >
            <motion.div whileHover={{ scale: 1.1 }} >
              <CardService>
                  <Icon type="star" />
                  <CardTitle>
                    Análisis de Datos
                  </CardTitle>
                  <CardSubTitle>
                    Métricas y gráficas con información que permite tomar decisiones
                  </CardSubTitle>
              </CardService>  
            </motion.div>
          </Col>

          <Col xs={24} sm={12} md={8} xl={6} >
            <motion.div whileHover={{ scale: 1.1 }} >
              <CardService>
                  <Icon type="star" />
                  <CardTitle>
                    Alcanzar Objetivos
                  </CardTitle>
                  <CardSubTitle>
                    Funcionalidades pensadas para lograr las metas de la organización
                  </CardSubTitle>
              </CardService>  
            </motion.div>
          </Col>

          <Col xs={24} sm={12} md={8} xl={6} >
            <motion.div whileHover={{ scale: 1.1 }} >
              <CardService>
                  <Icon type="star" />
                  <CardTitle>
                    Desarrollo Sustentable
                  </CardTitle>
                  <CardSubTitle>
                    Reemplazamos todo el consumo de papel en afiches, volantes e inscripciones.
                  </CardSubTitle>
              </CardService>  
            </motion.div>
          </Col>

          <Col xs={24} sm={12} md={8} xl={6} >
            <motion.div whileHover={{ scale: 1.1 }} >
              <CardService>
                  <Icon type="star" />
                  <CardTitle>
                    Retroalimentación
                  </CardTitle>
                  <CardSubTitle>
                    Recopilamos las opiniones para que puedas medir el éxito de tus proyectos.
                  </CardSubTitle>
              </CardService>  
            </motion.div>
          </Col>

          <Col xs={24} sm={12} md={8} xl={6} >
            <motion.div whileHover={{ scale: 1.1 }} >
              <CardService>
                  <Icon type="star" />
                  <CardTitle>
                    Optimización
                  </CardTitle>
                  <CardSubTitle>
                    A través de la tecnología se facilitan múltiples funciones haciendo todo más fácil.
                  </CardSubTitle>
              </CardService>  
            </motion.div>
          </Col>

          <Col xs={24} sm={12} md={8} xl={6} >
            <motion.div whileHover={{ scale: 1.1 }} >
              <CardService>
                  <Icon type="star" />
                  <CardTitle>
                    Formularios
                  </CardTitle>
                  <CardSubTitle>
                    Que recopilan información y permiten realizar seguimientos y segmentaciones.
                  </CardSubTitle>
              </CardService>  
            </motion.div>
          </Col>
        </Row>

      </CardServiceContainer>
    )
  }
}

const CardServiceContainer = styled.div`
  text-align: center;
  font-size: 28px;
  height: 100%;
  max-height: 600px !important;
`
const CardService = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.07);
  height: 190px;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;

  @media (max-width:767px){
    height: 170px;
  }

  @media (max-width:575px){
    max-width: 350px; 
    margin: 0 auto;
    margin-bottom: 20px;
    height: 155px;
  }
`
const CardTitle = styled.h1`
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #4E4E4E;
`

const CardSubTitle = styled.h1`
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.1px;
    color: #727272;
`
