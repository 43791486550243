import * as React from 'react'
import styled from 'styled-components'
import { Row, Col, notification  } from 'antd';
import Title from './Title'
import SubTitle from './SubTitle'
import ContactForm from './ContactForm'
import Picture from './Picture'
import Clients from './Clients'
import { sendContact } from '../../request/contact'

export default class Content extends React.Component {

  state = {
    values: { email: '', phone: '', fullName: '' },
    isLoading: false
  }

  handleChange = (key, value) => {
    this.setState(state => ({
      values: { ...state.values, [key]: value }
    }))
  }

  validarEmail(valor) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(valor)){
      return true
    } else {
      return false
    }
  }

  handleSubmit = async() => {
    const { values } = this.state
    this.setState({ isLoading: true})
    const data = {
      "Email": values.email,
      "Data": {
        "Phone": values.phone,
        "FullName": values.fullName
      }
    }
    //console.log('val', this.validarEmail(values.email))
    if(values.email === '' || values.phone === '' || values.fullName === '' ){
      this.setState({ isLoading: false})
      notification['error']({
        message: 'Campos vacíos',
        description:
          'Por favor no dejar campos vacíos',
      });
    }else if (!this.validarEmail(values.email)){
      this.setState({ isLoading: false})
      notification['error']({
        message: 'Email Invalido',
        description:
          'Por favor un email válido',
      });
    }else{
      
      sendContact(null, data)
      .then((resp)=>{
        this.setState({ isLoading: false})
        notification['success']({
          message: 'Mensaje Enviado',
          description:
            'Pronto uno de nuestros ejecutivos se contactará con usted',
        });
      })
      .catch((error)=>{
        this.setState({ isLoading: false})
        notification['error']({
          message: 'Ocurrio un Problema',
          description:
            'Ocurrió un problema inesperado, Por favor intente denuevo',
        });
      })
    }
  }

  render() {
    const { values, isLoading } = this.state
    return (
      <ContentContainer>
        <Row>
          <Col xs={24} md={11}>
            <ContentSection>
              <Title/>
              <SubTitle/>
              <ContactForm values={values} onChangeText={this.handleChange} onSubmit={this.handleSubmit} loading={isLoading}/>
            </ContentSection>
          </Col>
          <Col xs={24} md={13}>
            <ImageSection>
              <Picture/>
            </ImageSection>
          </Col>

          <Col span={24}>
            <Clients/>
          </Col>
        </Row>
      </ContentContainer>
    )
  }
}

const ContentContainer = styled.div`
  padding: 70px 80px 0;
  max-width: 1300px;
  margin: 0 auto;

  @media (max-width:767px){ 
    padding: 30px 20px 0;
    text-align: center;
  } 

  @media (max-width:376px){ 
    padding: 10px 20px 0;
    text-align: center;
  } 

`
const ContentSection = styled.div`
  @media (max-width:767px){ 
    margin: 400px 0 0;
  } 

  @media (max-width:568px){ 
    margin: 320px 0 0;
  } 

  @media (max-width:376px){ 
    margin: 250px 0 0;
  } 
`
const ImageSection = styled.div`
  @media (max-width:767px){ 
    margin: -850px 0 0;
  } 

  @media (max-width:568px){ 
    margin: -750px 0 0;
  }
  
  @media (max-width:376px){ 
    margin: -730px 0 0;
  }
`