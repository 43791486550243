import * as React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import Company from './Company'
import InfoContact from './InfoContact'
import Social from './Social'

export default class Info extends React.Component {

  render() {
    return (
      <InfoContainer>
        <Row>
          <Col xs={24} sm={24} lg={10}>
            <Company/>
          </Col>

          <Col xs={24} sm={12} lg={8}>
            <InfoContact/>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <Social/>
          </Col>
        </Row>
      </InfoContainer>
    )
  }
}

const InfoContainer = styled.div`
    padding: 50px 80px;
    max-width: 1300px;
    margin: 0 auto;

    @media (max-width:767px){ 
      padding: 100px 10px 0px 10px;
    }
    
`