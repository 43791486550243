import * as React from 'react'
import styled from 'styled-components'

export default class Title extends React.Component {

  render() {
    return (
      <TitleContainer>
        <Question>
          ¿Por qué utilizar nuestros servicios?
        </Question>

        <ServiceTitle>
          8 razones para elegirnos
        </ServiceTitle>

        <ServiceSubTitle>
          Sabemos lo dificil que es la organización de un evento, por lo que te hacemos más fácil el trabajo, 
          automatizando varias tareas y entregando una mejor experiencia a los asistentes para que tú puedas dedicarte a todo lo demás.
        </ServiceSubTitle>

      </TitleContainer>
    )
  }
}

const TitleContainer = styled.div`
  text-align: center;
`
const Question = styled.p`
  font-weight: 900;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #EA8A7F;

  @media (max-width:568px){ 
    font-size: 12px;
    line-height: 12px;
  }
`

const ServiceTitle = styled.h1`
  font-style: normal;
  font-weight: 1000;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -1.2px;
  color: #141414;

  @media (max-width:568px){ 
    font-size: 29px;
    line-height: 29px;
  }
`

const ServiceSubTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #616161;
  padding: 0 100px;

  @media (max-width:568px){ 
    font-size: 14px;
    padding: 0 10px;
  }
`