import * as React from 'react'
import styled from 'styled-components'

export default class Title extends React.Component {

    render() {
        return (
            <TitleContainer>
                <ContentTitle>
                    Completa Solución para la Gestión de Eventos y Congresos
                </ContentTitle>
            </TitleContainer>
        )
    }
}

const TitleContainer = styled.div`
    
`

const ContentTitle = styled.h1`
    font-weight: 800;
    font-size: 39px;
    line-height: 42px;
    letter-spacing: -1.2px;
    color: #FFFFFF;
    text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);

    @media (max-width:568px){ 
        font-size: 29px;
        line-height: 29px;
    }
`