import * as React from 'react'
import styled from 'styled-components'

export default class SubTitle extends React.Component {

    render() {
        return (
            <SubTitleContainer>
                <ContentSubTitle>
                    Nuestras plataformas aportan inteligencia en todo el proceso,
                    simplifican el trabajo y potencian el evento ayudando a conseguir más asistentes.
                </ContentSubTitle>
            </SubTitleContainer>
        )
    }
}

const SubTitleContainer = styled.div`
    
`

const ContentSubTitle = styled.p`
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.2px;
    color: #FFFFFF;
`