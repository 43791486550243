import * as React from 'react'
import styled from 'styled-components'

export default class LoginLink extends React.Component {

    render() {
        return (
            <LinkLogin href="https://trazoevents.com/admin">Iniciar Sesión</LinkLogin>
        )
    }
}

const LinkLogin = styled.a`
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #fff;

    @media (max-width: 568px ) {
        display: none;
    }
`