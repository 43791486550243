import * as React from 'react'
import styled from 'styled-components'
import IsoEvents from '../../assets/isoevents.png'


export default class Logo extends React.Component {
  
  render() {
    
    return (
      <LogoContainer>
          <LogoWrapper>
            <Iso src={IsoEvents} alt="Trazoevents" />
              <Strong>trazo</Strong>
              <Text>events</Text>
          </LogoWrapper>
      </LogoContainer>
    )
  }
}

const LogoContainer = styled.div`
  height: 40px;
  margin: 0px;
  
  ${({ marginRight }) => `margin-right: ${marginRight}px`}

  @media (max-width:767px){ 
    height: 20px;
    margin: 0px;
  }

  @media (max-height:400px){ 
    height: 24px;
    margin: 0px;
  }
`

const LogoWrapper = styled.div`
  margin: 0 auto;
  display: table-cell;
  vertical-align:middle;
  font-size: 36px;
  font-family: "Open Sans",sans-serif;
  line-height: 22px;

  @media (max-width:767px){ 
    font-size: 24px;
    line-height: 22px;
  }
`
const Iso = styled.img`
  height: 40px;
  margin-right: 2px;
  margin-top: -10px;
  @media (max-width:767px){ 
    height: 24px;
  }
`
const Strong = styled.span`
  display: inline-block;
  font-weight: 600;
  letter-spacing: -0.04em;
  color:#2D2D2E;
`

const Text = styled.span`
  display: inline-block;
  letter-spacing: -0.04em;
  color:#2D2D2E;
  margin-left: 0.02em;
 `