import * as React from 'react'
import styled from 'styled-components'
import { Input, Button } from 'antd';
import FullPageLoading from '../fullPageLoading/FullPageLoading'

export default function ContactForm ({ values, onChangeText, onSubmit, loading }) {

    return (
      <ContactFormContainer>
        <ContactTitle>
          Solicita una demo gratuita
        </ContactTitle>
        <InputContainer>
          <Input placeholder="Nombre Completo" value={values.fullName} onChange={(e) => onChangeText('fullName', e.target.value)}/>
        </InputContainer>

        <InputContainer>
          <Input placeholder="Email" value={values.email} onChange={(e) => onChangeText('email', e.target.value)}/>
        </InputContainer>

        <InputContainer>
          <Input placeholder="Teléfono" value={values.phone} onChange={(e) => onChangeText('phone', e.target.value)}/>
        </InputContainer>
          <ButtonSendContact onClick={onSubmit}>
          {!loading ? 'Quiero que me contacten': <FullPageLoading loading={loading}/>}
            </ButtonSendContact>
        <InfoContainer>
          <Info>
            Nos pondremos en contacto dentro de las próximas 24 horas.
          </Info>
        </InfoContainer>

      </ContactFormContainer>
    )
  
}

const ContactFormContainer = styled.div`
  max-width: 360px;
  width: 100%;
  padding: 30px;
  background: #FFFFFF;
  border-radius: 8px;
  float: right;

  @media (max-width:991px){ 
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;
    float: none;
  }
`

const ContactTitle = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.2px;
  color: #141414;
`

const InputContainer = styled.div`
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;
`
const InfoContainer = styled.div`
  width: 100%;
  max-width: 300px;
  padding-top: 10px;
`

const Info = styled.p`
  font-size: 12px;
  color: #727272;
`

const ButtonSendContact = styled(Button)`
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 32px !important;
  color: #fff !important;
  background: linear-gradient(357.34deg, #F9A97C -41.98%, #C84C88 175.68%) !important;
  border-radius: 6px !important;
  width: 100% !important;
  height: 40px !important;
  border: 0 !important;

  :hover{
    background: linear-gradient(357.34deg, #C84C88 -41.98%, #F9A97C 175.68%) !important;  
  }

  @media (max-width:767px){ 
    font-weight: 500 !important;
    font-size: 16px !important;
  }
`
