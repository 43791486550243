import React from 'react'
import styled from 'styled-components'
import WhatsAppLogo from '../assets/whatsapp-logo.png'

export default function WhatsAppButton() {
  return (
    <Container>
      <Link href="https://wa.me/56934512238" target="_blank">
        <img src={WhatsAppLogo} alt="Whatsapp"/>
      </Link>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 100%;
  text-align: right;
`

const Link = styled.a`
  display: inline-block;
  transition: all .2s ease-in-out;
  
  img {
    width: 100px;
    height: 100px;
  }
  
  &:hover {
    transform: scale(1.05);
  }
`