import * as React from 'react'
import styled from 'styled-components'
import Logo from './Logo'
import LoginLink from './LoginLink'
import ContactButon from './ContactButon'
import { Row, Col } from 'antd';

export default class Navbar extends React.Component {

    render() {
        return (
            <HeaderContainer>
                <RowContainer>
                    <Row>
                        <Col xs={24} sm={8} md={16} lg={17} xl={18}>
                            <LogoContainer>
                                <Logo></Logo>
                            </LogoContainer>
                        </Col>
                        <Col xs={12} sm={8} md={3} lg={3} xl={2}>
                            <LoginLinkContainer>
                                <LoginLink></LoginLink>   
                            </LoginLinkContainer> 
                        </Col>
                        <Col xs={12} sm={8} md={3} lg={3} xl={3}>
                            <ContactButonContainer>
                                <ContactButon></ContactButon>   
                            </ContactButonContainer> 
                        </Col>   
                    </Row>
                </RowContainer>
            </HeaderContainer>
        )
    }
}

const HeaderContainer = styled.div`
    width: 100%;
    height: 94px;
    left: 0px;
    top: 0px;
    z-index:1000;

    @media (max-width: 568px ) {
      height: 50px;
    }
`

const LogoContainer = styled.div`
    margin: 30px 0px 0px 50px;  
    display: -webkit-box;

    @media (max-width:767px){ 
        margin: 15px 0px 0px;  
        display: block !important;
    }
`

const LoginLinkContainer = styled.div`
    margin: 38px 0px 0px; 
    
    @media (max-width:767px){ 
        margin: 15px 0px 0px;  
    }
`

const ContactButonContainer = styled.div`
    margin: 35px 0px 0px; 
    
    @media (max-width:767px){ 
        margin: 15px 0px 0px;  
    }
`
const RowContainer = styled.div`
    max-width: 1300px;
    margin: 0 auto;
    text-align: left;
    

    @media (max-width:767px){ 
        text-align: center;
    }
`