import * as React from 'react'
import styled from 'styled-components'
import { Icon } from 'antd';

export default class Social extends React.Component {

    render() {
      return (
        <SocialContainer>

          <SocialTitle>
            Redes Scociales
          </SocialTitle>

          <SocialIcons>
            <NetworkLink href="https://www.facebook.com/trazoevents/" target="_blank">
              <Icon type="facebook" />
            </NetworkLink>

            {/* <NetworkLink href="#">
              <Icon type="google-plus" />
            </NetworkLink> */}

            {/* <NetworkLink href="#">
              <Icon type="twitter" />
            </NetworkLink> */}

            <NetworkLink href="https://www.instagram.com/trazolabs/" target="_blank">
              <Icon type="instagram" />
            </NetworkLink>

          </SocialIcons>

        </SocialContainer>
      )
    }
}

const SocialContainer = styled.div`
    padding: 50px 0 50px 20px;
`
const SocialTitle = styled.h4`
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #141414;
    padding-left: 10px;
`

const SocialIcons = styled.div`
    font-size: 30px;
`
const NetworkLink = styled.a`
    margin: 10px;
    color: #B9B9B9;
`