import * as React from 'react'
import styled from 'styled-components'
import LandImage from '../../assets/landing.png'

export default class Picture extends React.Component {

  render() {
    return (
    <ImageContainer>
      <Image src={LandImage}></Image>
    </ImageContainer>
    )
  }
}

const ImageContainer = styled.div`
    
`

const Image = styled.img`
  width: 100%;
`