import * as React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd';
import edifica from '../../assets/EDIFICA.png'
import fidelmov from '../../assets/FIDELMOV.png'
import flacma from '../../assets/FLACMA.png'
import hospital from '../../assets/HOSPITAL.png'
import veterinario from '../../assets/VETERINARIO.png'

export default class Clients extends React.Component {

  render() {
    return (
      <ClientContainer>
        <ClientTitle>
          Algunos de Nuestros Clientes
        </ClientTitle>

        <CLientsLogoContainer>
          <Row>
            <Col xs={24} sm={12} md={7} lg={4}>
              <ClientLogo src={flacma}/>
            </Col>

            <Col xs={24} sm={12} md={7} lg={4}>
              <ClientLogo src={edifica}/>
            </Col>

            <Col xs={24} sm={12} md={7} lg={4}>
              <ClientLogo src={hospital}/>
            </Col>

            <Col xs={24} sm={12} md={7} lg={4}>
              <ClientLogo src={fidelmov}/>
            </Col>
            <Col xs={24} sm={12} md={7} lg={4}>
              <ClientLogo src={veterinario}/>
            </Col>
          </Row>
        </CLientsLogoContainer>
      </ClientContainer>
    )
  }
}

const ClientContainer = styled.div`
  margin: 40px 0 0 40px;

  @media (max-width:767px){ 
    margin: 20px 10px;
    text-align: center;
  }
`

const ClientTitle = styled.h1`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #FFFFFF;
`

const CLientsLogoContainer = styled.div`
  margin: 50px 0;
  text-align: center;
  align-items: center;
`

const ClientLogo = styled.img`
  width: 100%;
  max-width: 250px;
  padding: 0 15px;
  margin-left: 60px;

  @media (max-width:991px){ 
    margin: 20px 20px;
  }

  @media (max-width:767px){ 
    margin: 20px 20px;
  }
`