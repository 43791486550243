import * as React from 'react'
import styled from 'styled-components'

export default class ContactText extends React.Component {

  render() {
    return (
      <ContactTextContainer>
      
        <ContactTextTitle>
          ¿Quieres llevar tu evento al siguiente nivel?
        </ContactTextTitle>

        <ContactTextSubtitle>
          Déjanos tus datos y uno de nuestros ejecutivos se pondrá en contacto contigo para coordinar una demostración gratuita.
        </ContactTextSubtitle>

      </ContactTextContainer>
    )
  }
}

const ContactTextContainer = styled.div`
  padding: 40px 0 40px 100px;

  @media (max-width:568px){ 
    padding: 40px 10px 0px 10px;
    text-align: center;
  }
`

const ContactTextTitle = styled.h1`
  font-weight: 800;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: -0.8px;
  color: #FFFFFF;

  @media (max-width:568px){ 
    font-size: 26px;
    line-height: 26px;
  }
`

const ContactTextSubtitle = styled.p`
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #FFFFFF;
`