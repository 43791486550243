import * as React from 'react'
import styled from 'styled-components'
import Logo from './Logo'

export default class Company extends React.Component {

    render() {
      return (
        <CompanyContainer>
          <Logo/>
          <CompanyDescription>
            Plataformas, aplicaciones móviles y otras soluciones para potenciar tus eventos.
          </CompanyDescription>
        </CompanyContainer>
      )
    }
}

const CompanyContainer = styled.div`
    padding: 50px;

    @media (max-width:568px){ 
      padding: 20px;
    }
`

const CompanyDescription = styled.p`
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #616161;
    padding-right: 50px;

    @media (max-width:568px){ 
      margin-top: 20px;
      padding: 0;
    }
`