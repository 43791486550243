import * as React from 'react'
import styled from 'styled-components'
import { Input, Button } from 'antd';
import FullPageLoading from '../fullPageLoading/FullPageLoading';

export default function ContactForm ({ values, onChangeText, onSubmit, loading }) {
    return (
      <ContactFormContainer>
        <InputContainer>
          <Input placeholder="Nombre Completo" value={values.fullName} onChange={(e) => onChangeText('fullName', e.target.value)}/>
        </InputContainer>

        <InputContainer>
          <Input placeholder="Email" value={values.email} onChange={(e) => onChangeText('email', e.target.value)}/>
        </InputContainer>

        <InputContainer>
          <Input placeholder="Teléfono" value={values.phone} onChange={(e) => onChangeText('phone', e.target.value)}/>
        </InputContainer>

          <ButtonSendContact onClick={onSubmit}>
            {!loading ? 'Solicita una demo gratuita': <FullPageLoading loading={loading}/>}
          </ButtonSendContact>
        
        <InfoContainer>
          <Info>
              Uno de nuestros ejecutivos se pondrá en contacto contigo dentro de las próximas 24 horas
          </Info>
        </InfoContainer>

      </ContactFormContainer>
    )
  
}

const ContactFormContainer = styled.div`
    
  @media (max-width:568px){ 
    text-align: center;
  }
`

const InputContainer = styled.div`
  width: 100%;
  max-width: 295px;
  margin-bottom: 10px;

  @media (max-width:767px){ 
    margin: 0 auto;
    margin-bottom: 20px;
  }

`
const InfoContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;
  max-width: 300px;
  padding: 10px 0px;

  @media (max-width:767px){ 
    margin: 0 auto;
    padding: 10px 0px;
  }
`

const Info = styled.p`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.1px;
  color: #FFFFFF;
`

const ButtonSendContact = styled(Button)`
  font-weight: 700 !important;
  font-size: 21px !important;
  line-height: 32px !important;
  color: #fff !important;
  background: linear-gradient(357.34deg, #F9A97C -41.98%, #C84C88 175.68%) !important;
  border-radius: 6px !important;
  padding: 5px 25px !important;
  height: 45px !important;
  border: 0 !important;
 

  :hover{
    background: linear-gradient(357.34deg, #C84C88 -41.98%, #F9A97C 175.68%) !important;  
  }

  @media (max-width:767px){ 
    font-weight: 500 !important;
    font-size: 16px !important;
    width: 295px !important;
  }
`
